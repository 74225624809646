<template>
    <Main :show-title="!isLoading && hasBudgets">

        <CardList v-if="isLoading" :columns="2">
            <Placeholder v-for="placeholder in placeholders[0]" :key="placeholder" element="li" :classes="[`card column-1`]" :height="237"/>
            <Placeholder v-for="placeholder in placeholders[1]" :key="placeholder" element="li" :classes="[`card column-2`]" :height="237"/>
        </CardList>

        <template v-else-if="!hasBudgets">
            <Empty v-if="!subscription.isActive" illustration="budgets">
                <template #title>Intet abonnement</template>
                <template #description>Tilknyt
                    <span @click="$router.push('/settings')" class="link">abonnement</span>
                    til konto
                </template>
            </Empty>
            <Empty v-else illustration="budgets">
                <template #title>Intet budget<br>tilføjet endnu</template>
                <template #description>Tilføj din
                    <router-link to="/budget/create">første</router-link>
                    budgetpost
                </template>
            </Empty>
        </template>

        <template v-else>
            <CardList v-if="budgets.length" :columns="columns">
                <BudgetCard v-for="budget in budgets" :key="budget.id" v-bind="budget"/>
            </CardList>
            <section v-for="(group, index) in groups" :key="index" class="group">
                <header class="card">
                    <small>{{ index }}</small>
                    <h3>{{ formatNumberToPrice(group.price) }} <span class="status-label smaller grey">Betalt {{ Math.round(group.paid / group.price * 100) }}%</span></h3>
                    <ActionButton icon="angle" :class="{ 'expanded' : !group.collapsed }" :secondary="true" :small="true" :label="group.collapsed ? 'Udvid' : 'Fold sammen'" @click="group.collapsed = !group.collapsed" />
                </header>
                <CardList :columns="columns" :class="{ 'collapsed' : group.collapsed }">
                    <BudgetCard v-for="budget in group.budgets" :key="budget.id" v-bind="budget"/>
                </CardList>
            </section>

        </template>
    </Main>

    <Summary v-if="hasBudgets" :budgets="budgets" :groups="groups" :is-loading="isLoading"/>

    <template v-if="hasBudgets">
        <ActionButton :icon="['plus', 'times']" :float="true" @click="showOverlayButton = !showOverlayButton" :focus-icon="focusIcon" />
        <OverlayButtons :show="showOverlayButton">
            <ActionButton icon="coins" label="Tilføj budgetpost" :disabled="!subscription.isActive" :tooltip="budgetButtonTooltip" :small="true" :secondary="true" @click="createBudgetPost"/>
            <ActionButton icon="money" label="Tilføj udgift" :disabled="!subscription.isActive" :tooltip="expenseButtonTooltip" :small="true" :secondary="true" @click="createExpense"/>
        </OverlayButtons>
    </template>
    <ActionButton v-else icon="plus" :float="true" @click="createBudgetPost" :disabled="!subscription.isActive" :tooltip="addButtonTooltip"/>

    <ModelRouterView/>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton.vue';
import Main from '@/components/Main.vue';
import Empty from '@/components/Empty.vue';
import useUnsubscribe from '@/utils/useUnsubscribe';
import { budgetsCollection } from '@/utils/collections';
import useState from '@/store';
import Placeholder from '@/components/Placeholder.vue';
import { computed, ref, watch } from 'vue';
import ModelRouterView from '@/components/ModelRouterView.vue';
import CardList from '@/components/CardList.vue';
import Summary from '@/components/Summary.vue';
import BudgetCard from '@/components/cards/BudgetCard.vue';
import OverlayButtons from '@/components/buttons/OverlayButtons.vue';
import methods from '@/utils/methods';

export default {
    name: 'Budgets',
    components: {
        CardList,
        ActionButton,
        Main,
        Empty,
        Placeholder,
        ModelRouterView,
        Summary,
        BudgetCard,
        OverlayButtons,
    },
    mixins: [methods],
    setup() {
        const { collections, subscription } = useState();
        const placeholders = collections.value?.budgets || [0, 0];
        const column = (budget) => budget.payment.price <= budget.payment.paid;
        const sort = [false, true];
        const { docs, isLoading, columns } = useUnsubscribe(budgetsCollection(), 0, column, sort);
        const groups = ref({});

        const budgets = computed(() => docs.value.filter((doc) => !('group' in doc) || doc?.group === ''));
        const budgetsWithGroups = computed(() => docs.value
            .filter((doc) => ('group' in doc) && doc.group !== '')
            .sort((a, b) => {
                if (a.group < b.group) { return -1; }
                if (a.group > b.group) { return 1; }
                return 0;
            }));
        const hasBudgets = computed(() => !!budgets.value.length || !!budgetsWithGroups.value.length);

        watch(() => budgetsWithGroups.value, () => {
            groups.value = {};
            budgetsWithGroups.value.forEach((budget) => {
                if (!(budget.group in groups.value)) groups.value[budget.group] = { price: 0, paid: 0, budgets: [], collapsed: true };
                groups.value[budget.group].budgets.push(budget);
                groups.value[budget.group].price += budget.payment.price + (budget.contract?.addition || 0);
                groups.value[budget.group].paid += budget.payment.paid;
            });
        });

        return { budgets, isLoading, placeholders, columns, subscription, groups, hasBudgets };
    },
    data() {
        return {
            showOverlayButton: false,
        };
    },
    computed: {
        focusIcon() {
            return Number(this.showOverlayButton);
        },
        addButtonTooltip() {
            return this.subscription.isActive ? 'Opret budgetpost' : 'Tilknyt et abonnement til din konto, for at oprette budgetposter';
        },
        budgetButtonTooltip() {
            return this.subscription.isActive ? 'Her opretter du nye delposter til dit budget' : 'Tilknyt et abonnement til din konto, for at oprette delposter';
        },
        expenseButtonTooltip() {
            return this.subscription.isActive ? 'Her indtastes udgifter med bilag' : 'Tilknyt et abonnement til din konto, for at oprette udgifter';
        },
    },
    methods: {
        createBudgetPost() {
            this.showOverlayButton = false;
            this.$router.push('/budget/create');
        },
        createExpense() {
            this.showOverlayButton = false;
            this.$router.push('/budget/create-expense');
        },
    },
};
</script>

<style lang="less">
@import "../assets/css/variables";

:is(div.header) + section.group {
    margin-top: 0;
}

h3 {
    font-weight: var(--semibold-weight);
    margin: 2.2rem 0 0;
    font-size: 2.4rem;
    line-height: 1.1;

    small {
        font-size: 1.2rem;
        font-weight: var(--bold-weight);
        text-transform: uppercase;
        color: rgb(var(--primary-color));
    }
}

@media @laptop-max-screen {
    #action-overlay div {
        bottom: calc(var(--menuitem-height) + 150px);
    }
}

section.group {
    background-color: rgb(var(--light-grey-color));
    padding: 20px;
    border-radius: var(--medium-border-radius);
    margin: 3rem 0;

    header {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        grid-auto-flow: column;
        padding-left: 20px;

        h3 {
            margin-top: 0;

            small {
                color: rgb(var(--grey-color));
            }

            .status-label {
                opacity: .6;
                margin-left: 1rem;

                @media @laptop-max-screen {
                    display: none;
                }
            }
        }

        button {
            grid-row: span 2;
            margin: 0;
            background-color: rgb(var(--white-color)) !important;
            padding: 0 2.4rem;

            &.expanded svg {
                transform: rotate(180deg);
            }

            @media @laptop-max-screen {
                span {
                    display: none;
                }
            }
        }
    }

    ul.card-list {
        margin-top: 2rem;

        &.collapsed {
            display: none;
        }

        li.card {
            border: 1px solid rgb(var(--semi-light-grey-color));
            background: rgb(var(--white-color));
        }
    }
}
</style>

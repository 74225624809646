<template>
    <Card :status-text="statusText" :status-color="statusColor" @click="onBudgetCard()" :column="column">
        <template #headline>
            <Icon v-if="file" name="paperclip"/>
            <Icon v-if="contract" name="contract"/>
            <span>{{ title }}</span>
            <span class="status-label smaller grey" v-if="this.contract && this.contract?.status !== 'ACCEPTED'">Kladde</span>
        </template>
        <p v-html="formatText(description)" class="format"></p>
        <p class="columns">
            <span>
                <small>Afsat beløb <i>/ {{ getPaymentTypeLabel(payment.type) }}</i></small><br>
                <strong>{{ formatNumberToPrice(payment.price) }}</strong>
            </span>
            <span>
                <small>Betalt</small><br>
                <strong class="larger">{{ formatNumberToPrice(payment.paid) }}</strong>
            </span>
        </p>
        <p class="columns">
            <span v-if="addition">
                <small>Tillæg/fradrag i alt</small><br>
                <strong>+{{ formatNumberToPrice(addition) }}</strong>
            </span>
            <span>
                <small :class="{ 'red' : payment.price + addition < payment.paid }">Rest til betaling</small><br>
                <strong>{{ formatNumberToPrice(payment.price + addition - payment.paid) }}</strong>
            </span>
        </p>
    </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import Icon from '@/components/Icon.vue';
import methods from '@/utils/methods';
import useState from '@/store';

export default {
    name: 'BudgetCard',
    components: {
        Card,
        Icon,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        file: {
            type: Object,
            default: null,
        },
        created: {
            type: Object,
            default: null,
        },
        contract: {
            type: Object,
            default: null,
        },
        payment: {
            type: Object,
            default: () => ({
                type: 'allocated',
                paid: 0,
                price: 0,
            }),
        },
        group: {
            type: String,
            default: null,
        },
        column: {
            type: Number,
            default: 1,
        },
    },
    setup() {
        const { setModalData } = useState();
        return { setModalData };
    },
    computed: {
        statusColor() {
            return this.isPaid() ? 'green' : 'yellow';
        },
        statusText() {
            const percent = Math.round((this.payment.paid / (this.payment.price + this.addition)) * 100);
            return `Betalt ${percent}%`;
        },
        addition() {
            return this.contract?.addition || 0;
        },
    },
    mixins: [methods],
    methods: {
        onBudgetCard() {
            const data = this.removeNullValues(this.$props);
            this.setModalData(data);
            this.$router.push({ name: 'view-budget', params: { id: data.id } });
        },
        isPaid() {
            return this.payment.price <= this.payment.paid;
        },
    },
};
</script>

<template>
    <li v-for="budget in budgets" :key="budget.id"
        :class="{ 'paid' : isPaid(budget) }">
        <span>{{ budget.title }} <span v-if="isPaid(budget)" class="status-label smaller grey">Betalt</span></span>
        <strong>{{ getPrice(budget) }}</strong>
    </li>
</template>

<script>
import methods from '@/utils/methods';

export default {
    name: 'BudgetLines',
    props: {
        budgets: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [methods],
    methods: {
        getPrice(budget) {
            const price = this.isPaid(budget) ? budget.payment.paid : this.getBudgetTotalPrice(budget);
            return this.formatNumberToPrice(price);
        },
        isPaid(budget) {
            return this.getBudgetTotalPrice(budget) <= budget.payment.paid;
        },
        getBudgetTotalPrice(budget) {
            return budget.payment.price + (budget?.contract?.addition || 0);
        },
    },
};
</script>

<style scoped lang="less">

</style>

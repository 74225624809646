<template>
    <aside :class="{ 'open' : openAside, 'compact' : compact }">
        <header class="card">
        <span>
            <small>Samlet budget</small><br>
            <strong class="larger">{{ formatNumberToPrice(totalBudgetsSum) }}</strong>
        </span>
            <ActionButton icon="details" :secondary="true" :small="true"
                          :label="openAside ? 'Skjul detaljer' : 'Se detaljer'"
                          @click="openAside = !openAside"/>
        </header>
        <h2 v-if="!compact">Samlet budget</h2>
        <ul>
            <template v-if="isLoading">
                <li v-for="index in budgets" :key="index">
                    <Placeholder :width="35"/>
                    <Placeholder element="strong" :width="20"/>
                </li>
            </template>
            <template v-else>
                <template v-if="!compact">
                    <li>
                        <h3 class="green header">Projektbudget</h3>
                        <Placeholder v-if="isLoading" element="strong" :width="20"/>
                        <h3 v-else><strong>{{ formatNumberToPrice(budget) }}</strong></h3>
                    </li>
                    <li>
                        <h3 class="green header">Budgetposter</h3>
                    </li>
                    <li class="list">
                        <ul>
                            <BudgetLines :budgets="budgets" />
                            <template v-for="(group, index) in groups" :key="index">
                                <li class="group"><strong>{{ index }}</strong></li>
                                <BudgetLines :budgets="group.budgets" />
                            </template>
                        </ul>
                    </li>
                </template>
                <li class="summary">
                    <span><strong>Samlet budgetposter</strong></span>
                    <Placeholder v-if="isLoading" element="strong" :width="40"/>
                    <strong v-else>{{ formatNumberToPrice(totalBudgetsSum) }}</strong>
                </li>
                <li>
                    Uforudsigelige udgifter {{ unforeseenExpensesPercent }}% <Icon name="pen" @click="$router.push({ name: 'view-project', params: { id: project.id }})"/>
                    <Placeholder v-if="isLoading" element="strong" :width="20"/>
                    <strong v-else>{{ formatNumberToPrice(unforeseenExpenses) }}</strong>
                </li>
                <li class="collected-budget">
                    <span
                        class="green"><strong>Samlet budgetposter inkl. uforudseelige</strong></span>
                    <Placeholder v-if="isLoading" element="strong" :width="20"/>
                    <strong v-else>{{ formatNumberToPrice(totalIncludedUnforeseenExpenses) }}</strong>
                </li>
            </template>
        </ul>
        <ul :style="`${compact ? 'margin-top: 2.3rem' : null}`">
            <li class="balance">
                <span class="green header">Balance</span>
                <Placeholder v-if="isLoading" element="strong" :width="20"/>
                <strong v-else :class="{ 'red' : difference < 0 }">{{ formatNumberToPrice(difference) }}</strong>
            </li>
            <li class="balance explainer">
                Projektbudgetet <strong>minus</strong> dine samlede budgetposter inkl. uforudsigelige
            </li>
            <li>
                <span>I alt betalt</span>
                <Placeholder v-if="isLoading" element="strong" :width="20"/>
                <strong v-else>{{ formatNumberToPrice(totalPaid) }}</strong>
            </li>
            <li>
                <span>Rest til betaling</span>
                <Placeholder v-if="isLoading" element="strong" :width="20"/>
                <strong v-else>{{ formatNumberToPrice(totalExpensesSum) }}</strong>
            </li>
        </ul>
    </aside>
</template>

<script>
import Placeholder from '@/components/Placeholder.vue';
import methods from '@/utils/methods';
import useState from '@/store';
import { projectsCollection } from '@/utils/collections';
import { ref, onMounted } from 'vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import BudgetLines from '@/components/BudgetLines.vue';
import Icon from '@/components/Icon.vue';

export default {
    name: 'Summary',
    components: {
        BudgetLines,
        Placeholder,
        ActionButton,
        Icon,
    },
    mixins: [methods],
    setup() {
        const { project } = useState();
        const budget = ref(0);
        const openAside = ref(false);
        const unforeseenExpensesPercent = ref(0);

        onMounted(async () => {
            const doc = await projectsCollection().doc(project.value.id).get();
            const projectData = doc.data();
            budget.value = projectData.budget;
            unforeseenExpensesPercent.value = projectData.unforeseenExpenses ?? 10;
        });

        return { budget, openAside, unforeseenExpensesPercent, project };
    },
    props: {
        budgets: {
            type: Array,
            default: null,
        },
        groups: {
            type: Object,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        totalBudgetsSum() {
            const budgetsSum = this.budgets.reduce((acc, budget) => acc + (budget.payment.price + (budget?.contract?.addition || 0)), 0);
            const groupsSum = Object.values(this.groups).reduce((acc, group) => acc + (group.price), 0);
            return budgetsSum + groupsSum;
        },
        totalPaid() {
            const budgetsSum = this.budgets.reduce((acc, budget) => acc + budget.payment.paid, 0);
            const groupsSum = Object.values(this.groups).reduce((acc, group) => acc + group.paid, 0);
            return budgetsSum + groupsSum;
        },
        totalExpensesSum() {
            return this.totalBudgetsSum - this.totalPaid;
        },
        unforeseenExpenses() {
            return (this.totalBudgetsSum / 100) * this.unforeseenExpensesPercent;
        },
        totalIncludedUnforeseenExpenses() {
            return this.totalBudgetsSum + this.unforeseenExpenses;
        },
        difference() {
            return this.budget - this.totalIncludedUnforeseenExpenses;
        },
    },
};
</script>

<style scoped lang="less">
    @import "../assets/css/variables";

    aside {
        background: rgb(var(--white-color));
        padding: var(--container-padding);
        border-radius: var(--large-border-radius);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: auto;

        @media @laptop-max-screen {
            --navigation: var(--menuitem-height) + 10px /* padding */ + 10px /* margin one side */;
            --aside-pull: var(--menuitem-height) - 42px - 75px;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translate3d(0, calc(100% - var(--aside-pull)), 0);
            box-shadow: 0 -10px 20px rgba(var(--semi-light-grey-color), 35%);
            padding-top: 30px;
            padding-bottom: calc(var(--navigation) + var(--container-padding));
            max-height: calc(100% - var(--header-height));
            transition: transform var(--time-transition) var(--cubic-transition);
            z-index: 2;

            &.open {
                transform: translate3d(0, 0, 0);

                header ~ * {
                    opacity: 1;
                }
            }
        }

        @media @laptop-screen {
            background: rgb(var(--light-grey-color));
            width: 440px;
            padding: 40px 36px;
            overflow: hidden;

            &:not(.compact) {
                margin: 20px;
                padding-bottom: 160px;
            }

            > ul > li.list > ul {
                width: 100%;
                overflow: auto;
                max-height: 150px;

                @media (min-height: 865px) {
                    max-height: 210px;
                }

                &::-webkit-scrollbar {
                    background: rgba(var(--dark-grey-color), 10%);
                    border: solid transparent;
                    background-clip: padding-box;
                    width: 3px;
                    border-width: var(--scroll-offset) 0 20px var(--scroll-left-push);
                }

                &::-webkit-scrollbar-thumb {
                    background: rgba(var(--dark-grey-color), 60%);
                    border: solid transparent;
                    background-clip: padding-box;
                    border-width: var(--scroll-offset) 0 20px var(--scroll-left-push);
                }
            }
        }

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                padding: 0 20px;
                margin: 0;
            }

            @media @laptop-max-screen {
                ~ * {
                    opacity: 0;
                    transition: opacity var(--time-transition) var(--cubic-transition);
                }
            }

            @media @laptop-screen {
                display: none;
            }
        }

        h2 {
            margin-bottom: auto;

            @media @laptop-max-screen {
                display: none;
            }

            @media (max-height: 710px) {
                display: none;
            }
        }

        h3 {
            font-weight: var(--semibold-weight);
            font-size: 2.4rem;
            line-height: 1.1;
            margin: 1.4rem 0 0;

            @media (min-height: 865px) {
                margin: 2.2rem 0 0;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: 1.4rem;
            line-height: 1;

            @media (min-height: 865px) {
                margin: auto 0 0;
            }

            :deep(li) {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                padding: 0 5px;

                &.group ~ li:not(.group) ::before {
                    content: "-";
                    margin-right: 8px;
                }

                &.list {
                    padding: 0;
                }

                &.collected-budget {
                    margin-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgb(var(--grey-status-background-color));
                }

                &.summary {
                    border-top: 1px solid rgb(var(--grey-status-background-color));
                    padding-top: 10px;
                    margin-bottom: 20px;
                    font-size: 1.5rem;
                }

                &.explainer {
                    display: block;
                    color: rgb(var(--grey-color));
                    margin-top: 0.6rem !important;
                }

                &.paid {
                    color: rgb(var(--green-status-text-color));

                    span.status-label {
                        margin-left: 16px;
                        opacity: .3;
                    }
                }

                &.balance {
                    padding-top: 20px;
                    @media (min-height: 865px) {
                        padding-top: 40px;
                    }

                    &.explainer {
                        padding-top: 0;
                        border-bottom: 1px solid rgb(var(--grey-status-background-color));
                        margin-bottom: 20px;
                        padding-bottom: 20px;
                        @media (min-height: 865px) {
                            padding-bottom: 40px;
                        }
                    }

                    strong {
                        font-weight: 700;
                        font-size: 1.5rem;
                    }
                }

                > svg {
                    margin: 0 auto 0 .7rem;
                    font-size: 0.8em;
                    cursor: pointer;

                    &:hover {
                        color: rgb(var(--primary-color));
                    }
                }

                .header {
                    text-transform: uppercase;
                    font-weight: var(--bold-weight);
                    font-size: 1.8rem;
                }

                .green {
                    color: rgb(var(--primary-color));
                }

                .red {
                    color: rgb(var(--red-status-text-color));
                }

                strong {
                    font-weight: var(--semibold-weight);
                }

                &:not(:first-child):not(.collected-budget) {
                    margin-top: .9rem;
                    @media (min-height: 865px) {
                        margin-top: 1.3rem;
                    }
                }
            }
        }
    }
</style>
